<template>
  <div class="general__card" :class="cardClass">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["cardClass"],
};
</script>
<style lang="scss" scoped>
.general__card {
  background: white;
  border-radius: 5px;
  border: 1.5px solid #EBEBEB;
}
.login__card,
.Register__card,
.RegisterComplete__card,
.verification__card,
.broker__profile__card {
  padding: 25px 32px;
  @include xs {
    padding: 25px 22px;
  }
}
.requested__card {
  padding: 50px 40px 28px 40px;
  @include xs{
    padding: 15px;
  }
}
.profile-card {
  padding: 2rem;
  @include xs{
    padding:1.25rem
  }
}
.broker__listing {
  &__card {
    justify-content: space-between;
  }
}

// transfer information card
.broker__addlistings {
  &__inputData__card {
    padding: 20px 32px;
    @include xs {
      padding: 20px 20px;
    }
  }
}

.schedule{
  &__card{
    max-width: 577px;
    padding:2.5rem 3rem 1.8125rem 3rem;
    @include xs{
      padding:2rem 1.875rem 1.5rem 1.875rem;
    }
  }
}
</style>
