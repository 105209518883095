<template>
  <div class="verification d-flex justify-content-center align-items-center">
    <Card cardClass="verification__card" v-if="!getLoadings.verification">
      <p
        class="text-danger"
        v-if="getErrors.verification && getErrors.verification.length"
      >
        {{ getErrors.verification }}
      </p>
      <template v-if="$route.query.type == 'broker'">
        <h1>
          {{ $t("Account is verified") }}
        </h1>
        <h1>
          {{ $t("Wait for approval") }}
        </h1>
        <p class="text-gray mt-3">
          {{
            $t(
              "Your account is verified. Now the platform Eerlijktehuur will check your  registration and approve it for you to gain access to the platform. "
            )
          }}
        </p>
      </template>
      <template v-else>
        <p class="verification__card__text">{{ $t("verification.text") }}...</p>
      </template>
    </Card>
    <Spinner v-else />
    <!-- <b-spinner label="Spinning" large variant="primary"></b-spinner> -->
  </div>
</template>

<script>
import Card from "@/components/general/Card.vue";
import { mapGetters, mapActions } from "vuex";
import Spinner from "../components/ui/Spinner.vue";
export default {
  components: {
    Card,
    Spinner,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("auth", ["userType", "user"]),
  },
  methods: {
    ...mapActions("auth", ["verify"]),
  },
  async created() {
    const query = this.$route.query;
    await this.verify({ query });
    if (query.type == "user") {
      setTimeout(() => this.$router.replace({ name: "UserLogin" }), 3000);
    }
  },
};
</script>

<style lang="scss">
.verification {
  height: 100%;

  &__card {
    margin-top: 100px;
    max-width: 496px;

    @include xs {
      max-width: 90%;
    }

    &__text {
      @include font(1.125rem, #4e4e4e, 400);

      @include xs {
        font-size: 1.1rem;
      }
    }
  }
}
</style>
